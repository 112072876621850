import React from "react"
import { FaAlignRight } from "react-icons/fa"
import PageLinks from "../constants/links"
import logo from "../assets/logo.png"
import "../css/navbar.css"
import "../css/main.css"
import { Link } from "gatsby"

const Navbar = ({ toggleSidebar }) => {
  return (
    <nav className="navbar noSelect">
      <div className="nav-center">
        <div className="nav-header noSelect">
          <Link to="/">
            <img src={logo} alt="logo" className="logo" />
          </Link>
          <button
            type="button"
            className="toggle-btn"
            onClick={() => {
              toggleSidebar()
            }}
          >
            <FaAlignRight></FaAlignRight>
          </button>
        </div>
        <PageLinks styleClass="nav-links"></PageLinks>
      </div>
    </nav>
  )
}

export default Navbar
