import React from "react"
import "../css/footer.css"
import {
  FaFacebook,
  FaLinkedin,
  FaTwitter,
  FaGithub,
  FaInstagram,
} from "react-icons/fa"

const Footer = () => {
  return (
    <footer className="footer noSelect">
      <h3>Time to Connect</h3>
      <div>
        <div className="all-icons noSelect">
          <div className="linkedin noSelect">
            <a
              href="https://www.linkedin.com/in/arpit-somani-83267b131"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="social-icon"></FaLinkedin>
            </a>
          </div>
          <div className="github">
            <a
              href="https://github.com/arpitsomani8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub className="social-icon"></FaGithub>
            </a>
          </div>
          <div className="instagram">
            <a
              href="https://www.instagram.com/arpit_somani8/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="social-icon"></FaInstagram>
            </a>
          </div>
          <div className="facebook">
            <a
              href="https://www.facebook.com/profile.php?id=100009587012206&viewas=100000686899395"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="social-icon"></FaFacebook>
            </a>
          </div>
          <div className="twitter">
            <a
              href="https://twitter.com/arpit_somani8/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className="social-icon"></FaTwitter>
            </a>
          </div>
        </div>

        <h4>
          copyright &copy;{new Date().getFullYear()}
          <br />
          <br />
          <span> Arpit Somani </span>
        </h4>
      </div>
    </footer>
  )
}

export default Footer
